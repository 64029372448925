import Navbar from '../externals/Navbar';
import Hero from '../externals/Hero';
import Footer from '../externals/Footer';

function NationalChampionship(){
    
    return(
        <div>
            <Navbar />
            <div className="container text-center">             
                    
            <iframe
            width="auto" height="900" className="col-lg-12 col-md-12 col-sm-12"
            srcDoc={`
                    <!doctype html>
                    <html>
                    <head>
                        <title>Tickets ordering</title>
                        <meta charset="utf-8">
                    </head>
                    <body align="center">
                        <script type="text/javascript" src="https://l.oveit.com/embed/d9a924dac5.js?l=ro" data-eventid="d9a924dac5" data-init="pending"></script>
                    </body>
                    </html>
                `} 
            />
                    
            </div>
        </div>
    )
}

export default NationalChampionship;