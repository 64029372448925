import Navbar from '../externals/Navbar';
import Hero from '../externals/Hero';
import Footer from '../externals/Footer';
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { useNavigate } from 'react-router';

function Results25(){

  const navigate = useNavigate();
  function showRequestedResults(event){

    switch(event.target.id)
    {
        case "EtapaI":
            alert("Nu sunt rezultate disponibile momentan!");
            break;
        
        case "EtapaII":
            alert("Nu sunt rezultate disponibile momentan!");
            break;

        case "EtapaIII":
            alert("Nu sunt rezultate disponibile momentan!");
            break;
        
        case "EtapaIV":
            alert("Nu sunt rezultate disponibile momentan!");
            break;
      
        case "EtapaV":
            alert("Nu sunt rezultate disponibile momentan!");
            break;
            
        case "EtapaVI":
            alert("Nu sunt rezultate disponibile momentan!");
            break;

        case "EtapaVII":
            alert("Nu sunt rezultate disponibile momentan!");
            break;

        case "EtapaVIII":
            alert("Nu sunt rezultate disponibile momentan!");
            break;

        case "EtapaIX":
            alert("Nu sunt rezultate disponibile momentan!");
            break;

        case "EtapaX":
            alert("Nu sunt rezultate disponibile momentan!");
            break;
            
        case "EtapaXI":
            alert("Nu sunt rezultate disponibile momentan!");
            break;  

        case "EtapaXII":
            alert("Nu sunt rezultate disponibile momentan!");
            break; 

        case "Overall":
            alert("Nu sunt rezultate disponibile momentan!");
            break;
            
        default:
            break;
    }

}

function showLiveResults()
{
navigate('/Live');
}

function goToResultSearch()
{
    navigate('/Search Results');
}

  return(
    <div> 
        <Navbar />
        <Hero />
        <br></br>
        <br></br> 
            <div className='container text-center'>
            <div className="card shadow-lg text-left">
                      <div className="card-body">
                          
                            <br></br>
                            <br></br>
                            <button className='btn btn-light' id='EtapaI' onClick={showRequestedResults}>Etapa I - Timisoara - 17, 18, 19 Ianuarie</button>
                            <br></br>
                            <br></br>
                            <button className='btn btn-light' id='EtapaII' onClick={showRequestedResults}>Etapa II - Cluj-Napoca - 7, 8, 9 Februarie</button>
                            <br></br>
                            <br></br>
                            <button className='btn btn-light' id='EtapaIII' onClick={showRequestedResults}>Etapa III - Cluj-Napoca - 21, 22, 23 Martie</button>
                            <br></br>
                            <br></br>
                            <button className='btn btn-light' id='EtapaIV' onClick={showRequestedResults}>Etapa IV - Joita - 11, 12, 13 Aprilie</button>
                            <br></br>
                            <br></br>
                            <button className='btn btn-light' id='EtapaV' onClick={showRequestedResults}>Etapa V Open Romania - Joita - 22, 23, 24, 25 Mai</button>
                            <br></br>
                            <br></br>
                            <button className='btn btn-light' id='EtapaVI' onClick={showRequestedResults}>Etapa V - Iasi - 12, 13, 14 Iunie</button>
                            <br></br>
                            <br></br>
                            <button className='btn btn-light' id='EtapaVII' onClick={showRequestedResults}>Etapa VI - Joita - 3, 4, 5, 6 Iulie</button>
                            <br></br>
                            <br></br>
                            <button className='btn btn-light' id='EtapaVIII' onClick={showRequestedResults}>Etapa VII - Bucecea - 7, 8, 9 August</button>
                            <br></br>
                            <br></br>
                            <button className='btn btn-light' id='EtapaIX' onClick={showRequestedResults}>Etapa VIII - Iasi - 11, 12, 13 Septembrie</button>
                            <br></br>
                            <br></br>
                            <button className='btn btn-light' id='EtapaX' onClick={showRequestedResults}>Etapa X Finala - Joita - 9, 10, 11 Octombrie</button>
                            <br></br>
                            <br></br>
                            <button className='btn btn-light' id='EtapaXI' onClick={showRequestedResults}>Cupa Romaniei - Cluj-Napoca - 13, 14, 15 Noiembrie</button>
                            <br></br>
                            <br></br>
                            <button className='btn btn-light' id='EtapaXII' onClick={showRequestedResults}>Cupa De Iarna - Timisoara - 12, 13, 14 Decembrie</button>
                            <br></br>
                            <br></br>
                            <button className='btn btn-light' id='Overall' onClick={showRequestedResults}>Overall</button>
                            <br></br>
                            <br></br>
                            <button className='btn btn-warning' id='live' onClick={showLiveResults}>Rezultate Live</button>
                            <br></br>
                            <br></br>
                            <button className='btn btn-warning' id='searchresults' onClick={goToResultSearch}>Cauta rezultate</button>
                            <br></br>
                            <br></br>
                           
                      </div>
              </div>
            </div>
      
        <br></br>   

        <Footer />
    </div>
    

);

}

export default Results25;