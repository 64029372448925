import Navbar from '../externals/Navbar';
import Hero from '../externals/Hero';
import Footer from '../externals/Footer';
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { useNavigate } from 'react-router';

function Results24(){

  const navigate = useNavigate();
  function showRequestedResults(event){

    switch(event.target.id)
    {
        case "EtapaI":
            window.open(require("../../docs/CN_2024_Etapa_I_results.pdf"));
            break;
        
        case "EtapaII":
            window.open(require("../../docs/CN_2024_Etapa_II_results.pdf"));
            break;

        case "EtapaIII":
            window.open(require("../../docs/CN_2024_Etapa_III_results.pdf"));
            break;
        
        case "EtapaIV":
            window.open(require("../../docs/CN_2024_Etapa_IV_results.pdf"));
            break;
        case "Open":
            window.open(require("../../docs/CN_2024_OPEN Romania_results.pdf"));
            break;
        case "EtapaV":
            window.open(require("../../docs/CN_2024_Etapa_V_results.pdf"));
            break;
        
        case "EtapaVI":
            window.open(require("../../docs/CN_2024_Etapa_VI_results.pdf"));
            break;

        case "EtapaVII":
            window.open(require("../../docs/CN_2024_Etapa_VII_results.pdf"));
            break;
        
        case "EtapaVIII":
            window.open(require("../../docs/Cupa Romaniei_2024_Results.pdf"));
            break;

        case "Overall":
            window.open(require("../../docs/Overall_Results_2024.pdf"));
            break;
        
        case "SINGLE":
            window.open(require("../../docs/European_Championship_2024_SINGLE_Results.pdf"));
            break;

        case "TEAMS":
            window.open(require("../../docs/European_Championship_2024_TEAMS_Results.pdf"));
            break;
            
        default:
            break;
    }

}

  return(
    <div> 
        <Navbar />
        <Hero />
        <br></br>
        <br></br> 
            <div className='container text-center'>
            <div className="card shadow-lg text-left">
                      <div className="card-body">
                            <br></br>
                            <br></br>
                            <button className='btn btn-light' id='SINGLE' onClick={showRequestedResults}>Results European Championship 2024 SINGLE</button>
                            <br></br>
                            <br></br>
                            <button className='btn btn-light' id='TEAMS' onClick={showRequestedResults}>Results European Championship 2024 TEAMS</button>
                            <br></br>
                            <br></br>
                            <button className='btn btn-light' id='EtapaI' onClick={showRequestedResults}>Rezultate Etapa I</button>
                            <br></br>
                            <br></br>
                            <button className='btn btn-light' id='EtapaII' onClick={showRequestedResults}>Rezultate Etapa II</button>
                            <br></br>
                            <br></br>
                            <button className='btn btn-light' id='EtapaIII' onClick={showRequestedResults}>Rezultate Etapa III</button>
                            <br></br>
                            <br></br>
                            <button className='btn btn-light' id='EtapaIV' onClick={showRequestedResults}>Rezultate Etapa IV</button>
                            <br></br>
                            <br></br>
                            <button className='btn btn-light' id='Open' onClick={showRequestedResults}>Rezultate OPEN Romania</button>
                            <br></br>
                            <br></br>
                            <button className='btn btn-light' id='EtapaV' onClick={showRequestedResults}>Rezultate Etapa V</button>
                            <br></br>
                            <br></br>
                            <button className='btn btn-light' id='EtapaVI' onClick={showRequestedResults}>Rezultate Etapa VI</button>
                            <br></br>
                            <br></br>
                            <button className='btn btn-light' id='EtapaVII' onClick={showRequestedResults}>Rezultate Etapa VII</button>
                            <br></br>
                            <br></br>
                            <button className='btn btn-light' id='EtapaVIII' onClick={showRequestedResults}>Rezultate Cupa Romaniei</button>
                            <br></br>
                            <br></br>
                            <button className='btn btn-light' id='Overall' onClick={showRequestedResults}>Overall 2024</button>
                            <br></br>
                            <br></br>
                            
                           
                      </div>
              </div>
            </div>
      
        <br></br>   

        <Footer />
    </div>
    

);

}

export default Results24;