import Navbar from '../externals/Navbar';

function EuropeanChampionship(){
    return(
        <div>
            <Navbar />
                
            
            <div className="container text-center">             

                 This event is not active anymore!   
                 
            </div>
            
        </div>
    )
}

export default EuropeanChampionship;